<script>
import { getChatConfigApi } from '@modules/it-infrastructure/it-infrastructure-api'
import Bus from '@utils/emitter'
import { PreferenceMethods } from '@state/modules/preference'
import { authComputed } from '@state/modules/auth'

export default {
  name: 'ChatProvider',
  provide() {
    const chatConfigContext = {}
    Object.defineProperty(chatConfigContext, 'config', {
      enumerable: true,
      get: () => {
        return this.config
      },
    })
    return { chatConfigContext }
  },
  data() {
    return {
      config: {},
    }
  },
  computed: {
    ...authComputed,
  },
  watch: {
    loggedIn(newValue) {
      if (newValue) {
        this.init()
      }
    },
  },
  created() {
    this.init()
  },
  methods: {
    ...PreferenceMethods,
    init() {
      this.getChatConfig()
      const handler = () => {
        this.getChatConfig()
        this.fetchPreferences()
      }
      Bus.$on('chat_config_updated', handler)
      this.$once('hook:beforeDestroy', () => {
        Bus.$off('chat_config_updated', handler)
      })
    },
    getChatConfig() {
      getChatConfigApi().then((data) => {
        this.config = data
        this.$emit('chat-config', this.config)
      })
    },
  },
  render() {
    return this.$scopedSlots.default({})
  },
}
</script>
