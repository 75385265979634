// import Color from 'color'
import {
  handleLoadEvent,
  renderLegendElements,
  handleRenderEvent,
} from './chart-events'

export default {
  title: {
    text: null, // widgetName || '',
    align: 'left',
    style: {
      color: 'var(--page-text-color)',
      fontSize: '14px',
    },
  },
  chart: {
    type: 'line',
    plotBorderColor: 'var(--border-color)',
    backgroundColor: 'var(--page-background-color)',
    style: {
      fontFamily: "'Poppins', sans-serif",
      color: 'var(--page-text-color)',
    },
    reflow: false,
    skipClone: true,
    resetZoomButton: {
      theme: {
        fill: 'var(--primary)',
        stroke: 'var(--primary)',
        r: 4,
        style: {
          color: 'var(--primary-button-text)',
        },
        states: {
          hover: {
            fill: 'var(--primary-button-hover-bg)',
            style: {
              color: 'var(--primary-button-hover-text)',
            },
          },
        },
      },
    },
    events: {
      load: handleLoadEvent,
      redraw: renderLegendElements,
      render: handleRenderEvent,
    },
  },
  boost: {
    useGPUTranslations: true,
    // Chart-level boost when there are more than 5 series in the chart
    seriesThreshold: 11,
  },
  colors: [
    '#099dd9',
    '#89c540',
    '#f5bc18',
    '#f58518',
    '#3279be',
    '#8d3abc',
    '#f04e3e',
  ],
  xAxis: {
    // dateTimeLabelFormats: {
    //   millisecond: '%I:%M:%S.%L %P',
    //   second: '%I:%M:%S %P',
    //   minute: '%I:%M %P',
    //   hour: '%I:%M %P',
    //   day: '%e %b',
    //   week: '%e %b',
    //   month: "%b '%y",
    //   year: '%Y',
    // },
    tickLength: 0,
    labels: {
      style: { color: 'var(--page-text-color)', fontSize: '10px' },
    },
    title: {
      style: { color: 'var(--page-text-color)', fontWeight: '500' },
    },
    gridLineColor: 'var(--border-color)',
  },
  yAxis: {
    // min: 0,
    reversedStacks: false,
    labels: {
      style: { color: 'var(--page-text-color)', fontSize: '10px' },
    },
    title: {
      style: { color: 'var(--page-text-color)', fontWeight: '500' },
    },
    gridLineColor: 'var(--border-color)',
  },
  tooltip: {
    useHTML: true,
    crosshairs: true,
    headerFormat: `{point.key}`,
    backgroundColor: 'var(--chart-tooltip-background)',
    style: { color: 'var(--page-text-color)' },
  },
  legend: {
    align: 'center',
    symbolWidth: 11,
    symbolHeight: 11,
    symbolRadius: 1,
    itemStyle: {
      color: 'var(--page-text-color)',
      fontWeight: 'normal',
      opacity: '1',
      fontSize: '10px', // FontSizeMap['small'],
    },
    itemHoverStyle: {
      color: 'var(--page-text-color)',
      fontWeight: 'normal',
      opacity: '1',
    },
    itemHiddenStyle: {
      opacity: '0.5',
    },
  },
  plotOptions: {
    series: {
      // minPointLength: 50,
      maxPointWidth: 50,
      boostThreshold: 3000,
      borderWidth: 0,
      animation: false,
      connectNulls: true,
      dataLabels: {
        color: 'var(--page-text-color)',
        shadow: false,
        style: {
          fontWeight: 'normal',
          textOutline: 'none',
        },
      },
      marker: {
        enabled: false,
        symbol: 'circle',
        fillColor: 'white',
        lineColor: null,
        lineWidth: 2,
        radius: 3,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
      states: {
        hover: {
          lineWidthPlus: 0,
        },
      },
      point: {
        events: {
          click() {
            let category =
              this.series.type === 'pie' ? this.name : this.category
            if (
              category &&
              this.series.userOptions.qualifications &&
              this.series.userOptions.widgetId &&
              this.series.userOptions.qualifications[this.index]
            ) {
              localStorage.setItem(
                'data-point-qualification',
                JSON.stringify(
                  this.series.userOptions.qualifications[this.index]
                )
              )
              this.series.userOptions.vm.$router.push(
                this.series.userOptions.vm.$modules.getModuleRoute(
                  'dashboard',
                  'view-kpi-widget',
                  {
                    params: {
                      type: 'widget',
                      id: this.series.userOptions.widgetId,
                    },
                    query: { label: category },
                  }
                )
              )
            }
          },
        },
      },
    },
  },
  credits: {
    enabled: false,
  },
  exporting: {
    tableCaption: false,
    enabled: false,
    allowHTML: true,
    buttons: {
      contextButton: {
        symbolFill: 'var(--neutral-lighter-button-text)',
        symbolStroke: 'var(--neutral-lighter-button-text)',
        menuItems: [
          'viewFullscreen',
          'separator',
          'downloadPNG',
          'downloadJPEG',
          'downloadPDF',
          'separator',
          'downloadCSV',
          'downloadXLS',
        ],
        x: 8,
        y: -10,
        theme: {
          fill: 'transparent',
          // fill: 'var(--neutral-lighter-button-bg)',
          // stroke: 'var(--neutral-lighter-button-text)',
          states: {
            hover: {
              fill: 'var(--neutral-lighter-button-bg)',
            },
            select: {
              fill: 'var(--neutral-lighter-button-bg)',
            },
          },
        },
      },
    },
  },
  noData: {
    style: {
      fontSize: '15px',
      color: 'var(--text-neutral-ligher)',
    },
  },
  lang: {
    noData: 'No data found',
    contextButtonTitle: 'Export',
  },
}
