<template>
  <div
    :style="{
      left: '82%',
      bottom: '20px',
      width: '310px',
      height: '90px',
    }"
    class="ongoing-call-box shadow-lg text-center cursor-pointer mb-4"
    @click="redirectToReminderTab"
  >
    <div class="ongoing-call-header">
      <div class="ongoing-call-header-container">
        <div class="ongoing-call-name">
          <div class="ongoing-call-name-span">
            <MRow>
              <MCol class="items-center mt-1" :size="12">
                <MIcon name="alarm-clock" size="lg" />
              </MCol>
            </MRow>
            <MRow>
              <MCol class="items-center font-semibold mt-1 text-lg" :size="12">
                <CallTimer
                  type="decrement"
                  start-time="00:05:00"
                  @time-up="() => $emit('close-reminder')"
                />
              </MCol>
            </MRow>
            <MRow :size="12" calss="mt-2">
              <MCol class="items-center" :size="12">
                <div>{{ `Minutes are remaining to callback` }}</div>
                <div>
                  {{ callBackRemiderData.requesterName || 'Unknown' }} ({{
                    callBackRemiderData.requesterNumber
                  }})
                </div>
              </MCol>
            </MRow>
          </div>
        </div>
      </div>
      <div class="ongoing-call-actions">
        <div @click="$emit('close-reminder')">
          <MIcon name="times" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CallTimer from './components/call-timer'
export default {
  name: 'CallbackReminderPopup',
  components: { CallTimer },
  props: {
    callBackRemiderData: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  methods: {
    redirectToReminderTab() {
      this.$emit('close-reminder')
      return this.$router.push(
        this.$modules.getModuleRoute('contactOps-manager', undefined, {
          params: { activeTab: 'call-reminder' },
          query: { activeTab: 'call-reminder' },
        })
      )
    },
  },
}
</script>
