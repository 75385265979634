<template>
  <div class="ai-bot-landing h-full p-5">
    <div class="header flex flex-1 items-center">
      <div class="flex flex-1 flex-col">
        <div>
          <img src="/images/hand-wave.svg" width="42px" height="42px" />
        </div>
        <div class="flex flex-1 flex-col">
          <div class="text-2xl">Hi there</div>
          <div class="text-xs">we are here to help you</div>
        </div>
      </div>
      <div class="flex">
        <img src="/images/star.svg" width="30px" height="30px" />
      </div>
    </div>
    <div class="content">
      <div class="content-box mt-2">
        <div class="p-3">
          <h6>Start a conversation</h6>
          <MButton @click="$emit('start-chat')">
            <MIcon name="paper-plane" /> Send us a message
          </MButton>
        </div>
        <MDivider class="m-0 p-0" />
        <div class="px-3 py-2">
          <a @click="$emit('view-conversation')">See all your conversations</a>
        </div>
      </div>
      <div class="content-box mt-3">
        <div class="p-3">
          <h6>Find your answer now</h6>
          <MInputGroup compact class="input-group-container">
            <div class="flex-1">
              <MInput
                v-model="searchTerm"
                class="bot-serch-input"
                auto-focus
                placeholder="Search our knowledge"
                @enterKey="searchKb"
              >
              </MInput>
            </div>
            <div
              class="cursor-pointer px-3 bg-primary flex items-center justify-center text-white rounded rounded-tl-none rounded-bl-none"
              style="display: inline-flex"
              @click="searchKb"
            >
              <MIcon name="chevron-right" />
            </div>
          </MInputGroup>
        </div>
      </div>
    </div>
    <div class="bot-background"></div>
  </div>
</template>

<script>
export default {
  name: 'AIBotLanding',
  data() {
    return {
      searchTerm: '',
    }
  },
  methods: {
    searchKb() {
      this.$router.push(
        this.$currentModule.getRoute('all-articles', {
          params: { keyword: this.searchTerm },
        })
      )
    },
  },
}
</script>

<style lang="less" scoped>
.input-group-container {
  top: 3px !important;
  display: flex !important;
  .@{ant-prefix}-input-number {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>

<style lang="less">
.input-group-container {
  .duration-type {
    .@{ant-prefix}-select-selection {
      @apply bg-neutral-lightest text-neutral;
    }
  }
}
.bot-serch-input:focus {
  outline: none;
  box-shadow: none;
}
</style>
