<template>
  <div ref="chart" class="h-full" v-on="$listeners" />
</template>
<script>
export default {
  props: {
    constructorType: {
      type: String,
      default: 'chart',
    },
    highcharts: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    updateArgs: {
      type: Array,
      default: () => [true, true],
    },
    callback: {
      type: Function,
      default: undefined,
    },
  },
  watch: {
    options: {
      handler(newValue) {
        // this.chart.options = newValue
        this.chart.update(newValue, ...this.updateArgs)
        this.chart.redraw()
      },
    },
  },
  mounted() {
    let HC = this.highcharts

    // Check wheather the chart configuration object is passed, as well as the constructor is valid
    if (this.options && HC[this.constructorType]) {
      this.chart = HC[this.constructorType](
        this.$refs.chart,
        this.options, // Always pass the deep copy when generating a chart. #80
        this.callback ? this.callback : null
      )
    } else {
      !this.options
        ? console.warn('The "options" parameter was not passed.')
        : console.warn(
            `'${this.constructorType}' constructor-type is incorrect. Sometimes this error is caused by the fact, that the corresponding module wasn't imported.`
          )
    }
    this.$_resizeObserver = new ResizeObserver((entries) => {
      if (this.chart) {
        this.chart.reflow()
      }
    })
    this.$_resizeObserver.observe(this.$refs.chart)
    document.addEventListener('fullscreenchange', this.handleFullScreen)
  },
  beforeDestroy() {
    // Destroy chart if exists
    if (this.chart) {
      this.chart.destroy()
    }
    if (this.$_resizeObserver) {
      this.$_resizeObserver.disconnect()
      this.$_resizeObserver = null
    }
    document.removeEventListener('fullscreenchange', this.handleFullScreen)
  },
  methods: {
    handleFullScreen() {
      if (this.chart) {
        setTimeout(() => {
          this.chart.reflow()
        }, 250)
      }
    },
  },
}
</script>
