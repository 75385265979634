<template>
  <div class="message" :class="{ 'my-message': isMyMessage }">
    <div class="flex" :class="{ 'justify-end': isMyMessage }">
      <!-- <div class="message-user">
        {{
          isMyMessage && user.id === senderId
            ? $t('me')
            : owner.name || message.ownerName
        }}
      </div> -->
      <div
        v-if="!isMyMessage"
        class="flex flex-col items-center justify-end mx-2"
      >
        <img
          v-if="senderId === -93101"
          src="@assets/images/logo/motadata.png"
          :height="size"
          :width="size"
          class="self-center"
        />
        <FlotoUserAvatar v-else :size="size" :user-id="owner.id" />
      </div>
      <UtilityPluginMessage
        v-if="
          [
            chatBrainType.PLUGIN_UTILITY,
            chatBrainType.SEARCH_UTILITY,
            chatBrainType.ACTION_UTILITY,
          ].indexOf(message.responseType) >= 0
        "
        :class="{ 'justify-end': isMyMessage }"
        :message="message"
        :is-my-message="isMyMessage"
      />
      <div
        v-else-if="!(attachments || []).length"
        class="content"
        :class="{ 'my-message': isMyMessage }"
        v-text="message.message"
      />
      <div
        v-else-if="attachments && (attachments || []).length"
        class="content cursor-pointer link"
        :class="{ 'my-message': isMyMessage }"
        @click="handleFileDownload(attachments[0])"
      >
        <MIcon name="file-alt" /> {{ attachments[0].realName }}
      </div>
      <div
        v-if="isMyMessage"
        class="flex flex-col items-center justify-end mx-2"
      >
        <img
          v-if="senderId === -93101"
          src="@assets/images/logo/motadata.png"
          :height="size"
          :width="size"
          class="self-center"
        />
        <FlotoUserAvatar v-else :size="size" :user-id="senderId" />
      </div>
    </div>
    <div class="message-datetime text-xs px-12 py-1">
      {{ date | datetime }}
    </div>
  </div>
</template>

<script>
import { TechnicianComputed } from '@state/modules/technician'
import { authComputed } from '@state/modules/auth'
import { transformAttachment } from '@data/attachment'
import UtilityPluginMessage from '@components/chat/ai-bot/message-renderer/utility-plugin-message.vue'
import { chatBrainType } from '@data/ai-bot'

export default {
  name: 'Message',
  components: { UtilityPluginMessage },
  props: {
    message: { type: [String, Object], default: undefined },
    senderId: { type: Number, required: true },
    isMyMessage: { type: Boolean, default: false },
    date: { type: [Number, Object], default: undefined },
    requester: { type: Object, default: undefined },
    participant: { type: Object, default: undefined },
    attachments: { type: Array, default: undefined },
  },
  data() {
    this.chatBrainType = chatBrainType
    this.size = 30
    return {}
  },
  computed: {
    ...TechnicianComputed,
    ...authComputed,
    owner() {
      const id = this.senderId
      const requester = this.requester
      if (requester && requester.id === id) {
        return requester
      }
      const participant = this.participant
      if (participant && participant.id === id) {
        return participant
      }
      const u = this.technicianOptions.find((t) => t.id === id)
      return u || {}
    },
  },
  methods: {
    handleFileDownload(attachment) {
      const a = transformAttachment(attachment, true, this.isPortalLogin)
      window.open(`${a.url}${this.accessToken}`, '_blank')
    },
  },
}
</script>

<style lang="less" scoped>
.link {
  text-decoration: underline;
  cursor: pointer;
}
</style>
