<template>
  <a @click="triggerApproval">
    <slot>
      <MIcon name="user-check" />
      <span class="ml-1">
        {{ $t('ask_for_approval') }}
      </span>
    </slot>
    <FlotoDrawerForm
      v-if="allowManualCreation"
      width="70%"
      :open="isDrawerOpen"
      @cancel="cancelAction"
      @submit="handleCreateApproval"
    >
      <template v-slot:header>
        {{ $t('create') }} {{ $tc('approval') }}
      </template>
      <FlotoFormItem
        v-if="formData.actions.length > 1"
        v-model="formData.subject"
        :label="$t('name')"
        :placeholder="$t('name')"
        rules="required"
      />
      <div v-for="(action, index) in formData.actions" :key="action.guid">
        <div class="flex items-center flex-wrap bg-neutral-lightest rounded">
          <span class="mx-3 rounded-counter fill">{{ index + 1 }}</span>
          <div class="flex-1 p-2">
            <ApprovalActionInput
              v-model="formData.actions[index]"
              :module-name="moduleName"
              :action-input-index="index"
              @remove="handleRemoveApprovalAction(action.guid)"
            />
          </div>
        </div>
        <div class="w-full">
          <MDivider v-if="index !== formData.actions.length - 1" dashed />
        </div>
      </div>
      <div v-if="canAddAction" class="flex my-2">
        <MButton
          id="add-stage-btn"
          variant="primary"
          outline
          @click="addNewApproval"
        >
          <MIcon name="plus-circle" />
          {{ $t('add') }} {{ $tc('stage') }}
        </MButton>
      </div>
      <template v-slot:actions="{ submit, hide }">
        <MButton id="create-btn" :loading="creatingApproval" @click="submit">
          {{ $t('create') }}
        </MButton>
        <MButton
          id="skip-btn"
          variant="neutral"
          class="mx-1"
          :loading="generatingApproval"
          @click="generateApproval"
        >
          {{ $t('skip') }}
        </MButton>
        <MButton id="cancel-btn" variant="default" class="mx-1" @click="hide">
          {{ $t('cancel') }}
        </MButton>
      </template>
    </FlotoDrawerForm>
  </a>
</template>

<script>
import { getApprovalSettingApi } from '@modules/approval/approval-api'
import { ApprovalTypeOptions } from '@data/approval'
import { generateId } from '@utils/id'
import { generateApprovalApi, createApprovalApi } from './approval-api'
import ApprovalActionInput from './components/approval-action-input'
import Config from './config'

export default {
  name: 'ApprovalAction',
  components: { ApprovalActionInput },
  props: {
    moduleName: { type: String, required: true },
    parentId: { type: Number, required: true },
    resourceName: { type: String, required: true },
  },
  data() {
    this.approvalTypeOptions = ApprovalTypeOptions()
    return {
      allowManualCreation: false,
      isDrawerOpen: false,
      creatingApproval: false,
      generatingApproval: false,
      formData: {
        actions: [
          {
            guid: generateId(),
            approvalType: 'unanimous',
            subject: `Approval Required for - ${this.resourceName}`,
          },
        ],
      },
    }
  },
  computed: {
    canAddAction() {
      return this.formData.actions.length < Config.MAX_LEVEL
    },
  },
  created() {
    this.setApprovalSettings()
  },
  methods: {
    cancelAction() {
      this.isDrawerOpen = false
      this.formData = {
        actions: [
          {
            guid: generateId(),
            approvalType: 'unanimous',
            subject: `Approval Required for - ${this.resourceName}`,
          },
        ],
      }
    },
    setApprovalSettings() {
      return getApprovalSettingApi()
        .then((data) => {
          this.allowManualCreation = data.allowToCreateManualApproval
        })
        .catch(() => {
          this.setApprovalSettings()
        })
    },
    triggerApproval() {
      this.$emit('trigger')
      if (this.allowManualCreation) {
        // show modal after dropdown is hide
        setTimeout(() => {
          this.isDrawerOpen = true
        }, 400)
      } else {
        this.generateApproval()
      }
    },
    generateApproval() {
      this.generatingApproval = true
      generateApprovalApi(this.moduleName, this.parentId)
        .then(() => {
          this.isDrawerOpen = false
          this.$emit('done')
        })
        .finally(() => {
          this.generatingApproval = false
        })
    },
    handleCreateApproval() {
      this.creatingApproval = true
      createApprovalApi(this.moduleName, this.parentId, this.formData)
        .then(() => {
          this.formData = {
            actions: [
              {
                guid: generateId(),
                approvalType: 'unanimous',
                subject: `Approval Required for - ${this.resourceName}`,
              },
            ],
          }
          this.isDrawerOpen = false
          this.$emit('done')
        })
        .finally(() => (this.creatingApproval = false))
    },
    addNewApproval() {
      let replaceSubject = false
      if (this.formData.actions.length === 1) {
        replaceSubject = true
      }
      this.formData = {
        ...this.formData,
        ...(replaceSubject
          ? { subject: this.formData.actions[0].subject }
          : {}),
        actions: [
          ...this.formData.actions,
          {
            guid: generateId(),
            approvalType: 'unanimous',
            subject: `Approval Required for - ${this.resourceName}`,
          },
        ],
      }
    },
    handleRemoveApprovalAction(guid) {
      let replaceSubject = false
      if (this.formData.actions.length === 2) {
        replaceSubject = true
      }
      const actions = this.formData.actions.filter((a) => a.guid !== guid)
      this.formData = {
        ...this.formData,
        ...(replaceSubject ? { subject: actions[0].subject } : {}),
        actions,
      }
    },
  },
}
</script>
