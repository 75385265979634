import api from '@api'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  transformCheckList,
  transformCheckListForServer,
} from '@data/check-list'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export function getCheckListApi(moduleName, id, params = {}) {
  return api
    .post(
      `${moduleName}/${id}/checklist/search/byqual`,
      {},
      {
        params: {
          ...params,
        },
        loader: false,
        notify: false,
      }
    )
    .then((data) => {
      return (data.objectList || []).map(transformCheckList)
    })
}
export function createCheckListApi(moduleName, id, data) {
  const requestData = transformCheckListForServer(data)
  return api
    .post(`${moduleName}/${id}/checklist`, requestData, {
      message: __rootT('added_successfully', {
        resource: __rootTc('check_list'),
      }),
    })
    .then(transformCheckList)
}

export function removeCheckListApi(moduleName, id, data) {
  return api.delete(`${moduleName}/${id}/checklist/${data.id}`, {
    message: __rootT('removed_successfully', {
      resource: __rootTc('check_list'),
    }),
  })
}

export function updateCheckListApi(moduleName, id, data) {
  const updateData = transformCheckListForServer(data)
  return api
    .patch(`${moduleName}/${id}/checklist/${data.id}`, updateData, {
      message: __rootT('updated_successfully', {
        resource: __rootTc('check_list'),
      }),
    })
    .then(transformCheckList)
}
