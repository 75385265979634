<template>
  <FormConsumer
    v-if="formFields.length"
    :value="resource"
    :form-fields="formFields"
    :disabled="disabled"
    :avoid-default-value="true"
    v-bind="$attrs"
    @submit="handleFormSubmitted"
  >
    <template v-slot:submit>
      <MButton :loading="updating" type="submit">
        {{ $t('update') }}
      </MButton>
    </template>
    <template v-slot:reset>
      <span />
    </template>
  </FormConsumer>
</template>

<script>
import Pick from 'lodash/pick'
import { FormComputed } from '@state/modules/form'
import { authComputed } from '@state/modules/auth'
import FormConsumer from '@components/form-consumer.vue'
import { getFieldIds } from '@data/form'
import { getFieldByRequesterAccess } from '@modules/support-portal/helpers/field-access'

export default {
  name: 'AdditionalInfo',
  components: { FormConsumer },
  props: {
    disabled: { type: Boolean, default: false },
    isApproval: { type: Boolean, default: false },
    moduleName: { type: String, required: true },
    stage: { type: String, default: undefined },
    resource: { type: Object, required: true },
    updateFn: { type: Function, required: true },
  },
  data() {
    return { updating: false }
  },
  computed: {
    ...FormComputed,
    ...authComputed,
    formFields() {
      if (!this[`${this.moduleName}Fields`]) {
        throw new Error(`given module ${this.moduleName} is not found in store`)
      }
      if (this.moduleName === this.$constants.REQUEST) {
        if (this.isPortalLogin) {
          const fields = this[`${this.moduleName}Fields`]({
            exclude: ['system', 'api'],
          }).filter((f) => f.useOnPortal)
          if (this.isApproval) {
            return fields
          }
          return getFieldByRequesterAccess(fields, this.user)
        }
        return this[`${this.moduleName}Fields`]({
          exclude: ['system', 'api'],
        })
      }
      if (this.moduleName === this.$constants.PURCHASE) {
        return this[`${this.moduleName}Fields`]().filter(
          (f) => !f.attributes.costField || f.attributes.costField === 'false'
        )
      }
      if (
        this.moduleName === this.$constants.CHANGE ||
        this.moduleName === this.$constants.RELEASE
      ) {
        return this[`${this.moduleName}Fields`]({
          stages: [`${this.stage}`],
          exclude: ['system'],
        })
      }
      return this[`${this.moduleName}Fields`]()
    },
  },
  methods: {
    handleFormSubmitted(data) {
      this.updating = true
      const fieldIds = getFieldIds(this.formFields)
      const updateData = Pick(data, fieldIds)
      return this.updateFn(updateData).finally(() => {
        this.updating = false
      })
    },
  },
}
</script>
