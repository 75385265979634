<template>
  <portal to="chat-container">
    <div
      class="chat-trigger flex items-center justify-center"
      @click="handleShowBot"
    >
      <MBadge :count="unreadCount" class="flex items-center justify-center">
        <MIcon name="comments" size="2x" />
      </MBadge>
    </div>
    <AIBot
      v-if="showAIBot"
      :ongoing-chat="ongoingChat"
      v-bind="$attrs"
      @start-new-conversation="$emit('start-new-conversation', $event)"
    />
  </portal>
</template>

<script>
import AIBot from './ai-bot'

export default {
  name: 'AIBotSupportChannel',
  components: { AIBot },
  inject: { chatConfigContext: { config: {} } },
  props: {
    ongoingChat: { type: Boolean, default: false },
  },
  data() {
    return {
      showAIBot: false,
      unreadCount: 0,
    }
  },
  methods: {
    handleShowBot() {
      this.showAIBot = !this.showAIBot
    },
  },
}
</script>
