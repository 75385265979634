<template>
  <FlotoDrawer :open="showDrawer" @hide="hideDrawer">
    <template v-slot:title> {{ $t('insert') }} {{ $t('knowledge') }} </template>
    <template v-slot:trigger>
      <a
        href="javascript:;"
        class="text-neutral mx-2"
        @click="showDrawer = true"
      >
        <MIcon name="lightbulb-on" class="mx-1" />
        {{ $t('insert') }} {{ $t('knowledge') }}
      </a>
    </template>
    <SuggestedKnowledge :resource="resource" @insert="handleInsertKnowledge" />
    <KBSelectionList
      ref="kbListRef"
      selectable
      searchable
      :max-allowed-selection="1"
      :fetch-fn="knowledgeType === 'suggestedKb' ? fetchKnowledgeFn : undefined"
      @selection-change="setSelectedItems"
    >
      <template v-slot:after-title>
        <FlotoDropdownPicker
          v-model="knowledgeType"
          as-input
          :options="options"
          :allow-clear="false"
          @change="handleTypeChange"
        >
          <template v-slot:trigger="{ currentItem, toggle }">
            <div
              class="inline-block mx-2 text-neutral cursor-pointer text-base"
              @click="toggle"
            >
              {{ (currentItem || {}).text }}
              <MIcon name="chevron-down" class="mx-1" />
            </div>
          </template>
        </FlotoDropdownPicker>
      </template>
    </KBSelectionList>
    <template v-slot:actions="{ hide }">
      <MButton
        :disabled="selectedItems.length === 0"
        outline
        class="mr-2"
        :loading="processing"
        @click="handleAddKb"
      >
        {{ $tc('insert') }} {{ $tc('knowledge') }}
      </MButton>
      <MButton
        :disabled="selectedItems.length === 0"
        outline
        class="mr-2"
        :loading="processing"
        @click="handleAddLink"
      >
        {{ $tc('insert') }} {{ $tc('link') }}
      </MButton>
      <MButton variant="default" @click="hide">
        {{ $t('cancel') }}
      </MButton>
    </template>
  </FlotoDrawer>
</template>

<script>
import { authComputed } from '@state/modules/auth'
import { relatedKnowledgeApi } from '@modules/knowledge/knowledge-api'
import KBSelectionList from '@components/item-selection-list/knowledge-list'
import SuggestedKnowledge from './suggested-knowledge.vue'
export default {
  name: 'InsertKnowledge',
  components: { KBSelectionList, SuggestedKnowledge },
  inject: ['suggestedFieldValuesContext'],
  props: {
    resource: { type: Object, required: true },
  },
  data() {
    this.options = [
      { text: this.$tc('suggested_kb'), key: 'suggestedKb' },
      { text: this.$tc('all_kb'), key: 'allKb' },
    ]
    return {
      processing: false,
      showDrawer: false,
      selectedItems: [],
      knowledgeType: 'suggestedKb',
    }
  },
  computed: {
    ...authComputed,
  },
  methods: {
    handleTypeChange() {
      this.$nextTick(() => {
        this.refresh()
      })
    },
    fetchKnowledgeFn() {
      return relatedKnowledgeApi(this.isPortalLogin, this.resource.subject)
    },
    handleAddKb() {
      this.$emit('selected', this.selectedItems[0])
      this.hideDrawer()
      this.selectedItems = []
    },
    handleAddLink() {
      this.$emit('selected', this.selectedItems[0], true)
      this.hideDrawer()
      this.selectedItems = []
    },
    hideDrawer() {
      this.showDrawer = false
    },
    setSelectedItems(items) {
      this.selectedItems = items
    },
    refresh() {
      this.$refs.kbListRef.refresh()
    },
    handleInsertKnowledge(kb) {
      this.$emit('selected', kb)
      this.hideDrawer()
    },
  },
}
</script>
