import Moment from 'moment'
import Uniq from 'lodash/uniq'
import Duration from '@src/filters/duration'
import NumerFormat from '@src/filters/number-format'
import buildConfig from '@components/chart/build-chart-config'
import bytesPerSecToSize, { bytesToSize } from '@src/filters/bytes'
import { getAllowedUnit, applyUnit } from '@utils/unit-converter'

export default function getWidgetChartCommonOptions(
  data,
  widgetProperties,
  options
) {
  const labelProperty = (widgetProperties || {}).labelProperty || {}
  const units = data.map((series) => getAllowedUnit(series.counter))
  const hasUnknownUnits = units.filter((u) => !u).length > 0
  const uniqUnits = hasUnknownUnits ? [] : Uniq(units)

  return buildConfig({
    timezone: options.timezone,
    title: {
      text: null, // widgetName || '',
      align: 'center',
    },
    series: data,
    legend: {
      // enabled: labelProperty.legendEnabled,
      enabled: true,
    },
    xAxis: {
      tickLength: 0,
      title: {
        text: labelProperty.xAxisTitleEnabled
          ? labelProperty.xAxisTitle || ''
          : undefined,
      },
      labels: {
        rotation: isNaN(labelProperty.rotation) ? 0 : labelProperty.rotation,
        style: {
          ...(labelProperty.ellipsisEnabled
            ? {
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }
            : {}),
        },
      },
    },
    yAxis: {
      title: {
        text: labelProperty.yAxisTitleEnabled
          ? labelProperty.yAxisTitle || ''
          : undefined,
      },
      labels: {
        // rotation: isNaN(labelProperty.rotation)
        //   ? 0
        //   : labelProperty.rotation,
        style: {
          ...(labelProperty.ellipsisEnabled
            ? {
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }
            : {}),
        },

        ...(uniqUnits.length === 1 && ['percent'].includes(uniqUnits[0])
          ? {
              formatter: function() {
                return `${this.value}%`
              },
            }
          : {}),

        ...(uniqUnits.length === 1 && ['ms'].includes(uniqUnits[0])
          ? {
              formatter: function() {
                return `${this.value < 0 ? '-' : ''}${Duration(
                  this.value,
                  'milliseconds',
                  false,
                  undefined,
                  true
                )}`
              },
            }
          : {}),

        ...(uniqUnits.length === 1 && ['bytes', 'bps'].includes(uniqUnits[0])
          ? {
              formatter: function() {
                if (this.value === 0) {
                  return 0
                }
                const unit = uniqUnits[0]
                return unit === 'bps'
                  ? bytesPerSecToSize(this.value)
                  : bytesToSize(this.value)
              },
            }
          : {}),
      },
      // ...(uniqUnits.length === 1 && ['bytes', 'bps'].includes(uniqUnits[0])
      //   ? {
      //       tickInterval: 1024,
      //     }
      //   : {}),
    },
    tooltip: {
      valueSuffix:
        options.unit || uniqUnits.length > 0 ? uniqUnits[0] : undefined,
      ...(options.omitTooltipFormatter
        ? {}
        : {
            formatter: function() {
              const momentInstance = options.hasDateTime
                ? Moment.utc(
                    Moment.unix(this.x / 1000).format('YYYY-MM-DD HH:mm:ss')
                  )
                : {
                    isValid() {
                      return false
                    },
                  }
              const x = momentInstance.isValid()
                ? options.timezone
                  ? momentInstance.tz('UTC').format(options.dateFormat)
                  : momentInstance.format(options.dateFormat)
                : this.x || this.point.name
              let points = this.points ? this.points : [this.point]
              let counter
              let tooltip = ''
              points.forEach((point) => {
                if (point.series && point.series.userOptions.counter) {
                  counter = point.series.userOptions.counter
                }
              })
              points.forEach((point, index) => {
                const p = point.point || point
                const series = point.series
                let rawValue = point.y
                if (p.series.type === 'sankey') {
                  if (p.isNode) {
                    rawValue = p.sum
                  } else {
                    rawValue = p.weight
                  }
                }
                let value =
                  counter && getAllowedUnit(counter)
                    ? applyUnit(counter, rawValue)
                    : NumerFormat(rawValue)
                if (
                  !counter &&
                  (point.series.userOptions.formattedValues || []).length > 0
                ) {
                  value = point.series.userOptions.formattedValues[point.index]
                }
                let instance = options.useInstanceInTooltip
                  ? series.options.instance
                  : null
                if (p.from && p.to) {
                  tooltip += `${series.name} ${
                    instance && series.name.indexOf(instance) === -1
                      ? `(${instance}) `
                      : ''
                  } <b>${p.from} - ${p.to} : ${value}</b><br />`
                  return
                }
                tooltip += `${series.name} ${
                  instance && series.name.indexOf(instance) === -1
                    ? `(${instance}) `
                    : ''
                } <b>${index === 0 ? x || '' : ''} : `
                if (p && p.low && p.high) {
                  tooltip += `${
                    counter ? applyUnit(counter, p.low) : NumerFormat(p.low)
                  } - ${
                    counter ? applyUnit(counter, p.high) : NumerFormat(p.high)
                  }</b><br />`
                } else {
                  tooltip += `${value}</b><br />`
                }
              })
              return tooltip
            },
          }),
    },
    plotOptions: {
      ...(options.plotOptions || {}),
    },
    ...(options.serverSideZoom && options.hasDateTime
      ? { serverSideZoom: 10000 }
      : {}),
  })
}
